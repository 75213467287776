import axios from "axios";//创建一个axios的对象
//生成一个axios的实例
const http = axios.create({
    // baseURL: process.env.NODE_ENV === 'development' ? window._$api.devUrl : window._$api.proUrl, // url = base url + request url
    baseURL: process.env.NODE_ENV === 'development' ? "http://47.99.176.18:9999" : window._$api.proUrl, // url = base url + request url
    timeout: 1000 * 60 // request timeout
});


export default http;//导出

// request interceptor
http.interceptors.request.use(
    config => {
        // console.log("token", window.localStorage.getItem("token"))
        // config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
        config.headers['Content-Type'] = 'application/json'
        config.headers['Authorization'] = window.token
        return config
    },
    error => {
        return Promise.reject(error)
    }
)


http.interceptors.response.use(
    response => {
        return response.data
    },
    error => {
        return Promise.reject(error);
    }
)
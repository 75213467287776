import request from "@/utils/request";

/**
 * 根据uuid 查看点赞信息
 * @param {Object} query { uuid: String}
 * @returns 
 */
export function postLikeInfo (query) {
    return request.post('/keleScore/likeInfo', query);
}

/**
 * 点赞 分享链接的uuid
 * @param {Object} query { uuid: String}
 * @returns 
 */
export function postLike (query) {
    return request.post('/keleScore/like', query);
}


export function getUserRaffle (query) {
    return request.post('/games/keleRaffle/getUserRaffle', query);
}


export function raffle (query) {
    return request.post('/games/keleRaffle/raffle', query);
}

export function giveCard (query) {
    return request.post('/games/keleRaffle/giveCard', query);
}